export default {
  data() {
    return {
      countDownValue: null,
      countDownSpeed: 1000
    };
  },
  watch: {
    countDownValue(value) {
      let numValue = Number(value);
      if (!this._countDownInterval && !isNaN(numValue) && numValue > 0) {
        this.startCountDown(numValue);
      }
    }
  },
  methods: {
    startCountDown(value) {
      this.countDownValue = value;
      this._countDownInterval = setInterval(() => {
        this.countDownValue--;
        if (this.countDownValue == 0) {
          clearInterval(this._countDownInterval);
          this._countDownInterval = null;
          this.onCountDownEnd();
        }
      }, this.countDownSpeed);
    }
  }
};
